<template>
  <div class="AgencyAccount">
    <img src="../../assets/product/AgencyAccount/1.png" class="headerimg" />
    <div class="advantage">
      <div class="title">服务优势</div>

      <div class="advantagelist">
        <div class="advantageimg">
          <img src="../../assets/product/AgencyAccount/2.png" alt="" />
        </div>
       <div style="display: inline-block;">
          <div class="advantagecard">
          <div class="advantageinfo advantageinforight">
            <div class="infoline">
              <img src="../../assets/product/AgencyAccount/3.png" alt="" />
              <span>智能记账报税</span>
            </div>
            <div class="infotext">
              邦伲德自研智能财税平台，结合多年财务会计记账经验形成的标准化记账报税流程，提供省钱、省时、省心的一站式财税服务。
            </div>
          </div>

           <div class="advantageinfo">
            <div class="infoline">
              <img src="../../assets/product/AgencyAccount/4.png" alt="" />
              <span>专属服务团队</span>
            </div>
            <div class="infotext">
              平均5年以上服务经验，多名会计对接一个客户，分工明确，高效运作，保证财务会计记账报税专业的同时高质量的完成服务。
            </div>
          </div>
        </div>

        <div class="advantagecard advantagecardtop">
          <div class="advantageinfo advantageinforight">
            <div class="infoline">
              <img src="../../assets/product/AgencyAccount/5.png" alt="" />
              <span>财税风险预警</span>
            </div>
            <div class="infotext">
             "系统自检"+"会计核查"双核把控，代理记账报税服务期间实时预警财务风险，财税专家提供规避方案。
            </div>
          </div>

           <div class="advantageinfo">
            <div class="infoline">
              <img src="../../assets/product/AgencyAccount/6.png" alt="" />
              <span>专业档案管理</span>
            </div>
            <div class="infotext">
              邦伲德为每一位客户创建专属财税档案库，银行级恒温恒湿的环境保证客户的重要财务数据能更安全的保存。
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>


    <div class="information">
      <div class="title">准备资料</div>
      <div class="informationimg">
        <img src="../../assets/product/AgencyAccount/7.png" alt="">
      </div>
    </div>

    <div class="information " style="background: #fff;">
      <div class="title">服务流程</div>
      <div class="informationimg">
        <img src="../../assets/product/AgencyAccount/8.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.advantage {
  margin: 139px auto;
}

.information{
  background: #F5F5F9;
  padding-top: 140px;
  padding-bottom: 134px;
  .informationimg{
    margin-top: 58px;
    text-align: center;
    img{
      width: 1008px;
    }
  }
}

.advantagelist {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  .advantageimg {
    img {
      width: 489px;
    }
  }
.advantagecardtop{
  margin-top: 60px;
}

  .advantagecard {
    
    margin-left: 137px;
    .advantageinforight{
      margin-right: 120px;
    }
    .advantageinfo {
      display: inline-block;
      .infoline {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        span {
          vertical-align: middle;
          margin-left: 10px;
        }
        img {
          width: 50px;
          vertical-align: middle;
        }
      }

      .infotext {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
        width: 273px;
        text-align: left;
      }
    }
  }
}
</style>